<template>
  <div>
		<damo-header/>
    <div class="banner-box">
    	<div class="banner-con">
    		<img src="../static/images/banner_02.gif">
    	</div>
    </div>    
    <div id='testNavBar'></div>     
    <div :class='{ fixedNavbar: isfixTab }'>
      <div class="box pNavbar">
        <div class="flex Navbar-box"> 
          <div class="Navbar-div" v-for="(item, index) in menuList" :key="index" @click="jump(index)" :class="[menunum === index ? 'current' : '']">
            <img v-show="menunum !== index" class="menu-img" :src="item.img">
            <img v-show="menunum === index" class="menu-img" :src="item.imgs">
            <div class="menu-div">
              <p class="p01">{{ item.texta }}</p>
              <p class="p02">{{ item.textb }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isfixTab" style="width: 100%; height: 150px;"></div>
    
    <div class="box gic-box section" style="overflow: hidden;">
      <div class="product-title fw">GIC系统</div>
			<div class="gic-boxs">
				<div class="gic-boxs-div">
					<div :class="[indexnum === index ? 'moren moren-show' : 'moren']" v-on:mouseover="changeActive($event, index)" v-on:mouseout="removeActive($event)" v-for="(item, index) in gicList" :key="index">
						<div class="gic-div">
							<div class="gic-off">
								<img class="gic-off-img" :src="item.img">
								<p class="p01">{{ item.texta }}</p>
								<p class="p01">{{ item.textb }}</p>
							</div>
							<div class="gic-on-div">
								<div class="gic-on">
									<img class="gic-off-pre" :src="item.imgs">
									<p class="p01 fw">{{ item.textc }}</p>
									<p class="p02">{{ item.textd }}</p>
									<p class="p03">功能点</p>
									<p class="p04">{{ item.texte }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>
    <div class="app-box xx-bgs section">
      <div class="box">
        <div class="product-title fw">好办工作台</div>
        <damo-swiper ref="DamoSwiper"></damo-swiper>
      </div>
    </div>
    <div class="box section">
      <div class="product-title fw">观云台</div>
      <div class="gyt-boxl">
        <div class="line-hide">
          <p class="p01">移动数据BI</p>
          <p class="p02">智能助理</p>
        </div>        
        <p class="p03">集成GIC、好办统计数据，解决管理层关注信息分散的问题；小程序随时随地查看，解决PC端数据查看不便的痛点。</p>
      </div>
    </div>
    <div class="app-box xx-bgs section">
      <div class="box">
        <div class="product-title fw">公众号小程序</div>
        <div class="xcx-title">
          <span class="xcx-line">会员服务</span>
          <span>积分商城</span>
        </div>
        <div class="cxc-con">
          <div class="div01 fl">
            <img src="../static/images/xcx_img01.png" alt="">
						<p class="cxc-con-line"></p>
            <p class="cxc-con-tle">福利中心-积分商城</p>
          </div>
          <div class="div02 fl">
            <img src="../static/images/xcx_img02.png" alt="">
						<p class="cxc-con-line"></p>
            <p class="cxc-con-tle">交易中心-微信商城</p>
          </div>
          <div class="div03 fl">
            <img src="../static/images/xcx_img03.png" alt="">
						<p class="cxc-con-line"></p>
            <p class="cxc-con-tle">资产中心-会员权益</p>
          </div>
        </div>
      </div>
    </div>
    <div class="box section">
      <div class="product-title fw">DataHook</div>
      <div class="datahook-box">
        <div class="div01">
          <p>全域数据源</p>
          <p>数据报表自定义</p>
        </div>
        <div class="div02 fw">数据管理平台</div>
        <div class="div03">
          <p>指标组合自定义</p>
          <p>数据安全可视化</p>
        </div>
      </div>
    </div>
		<damo-footer @footclick="handleFoot"/>
  </div>
</template>
<script>
	import DamoHeader from '@/components/DamoHeader.vue'
  import DamoSwiper from '@/components/DamoSwiper.vue'
	import DamoFooter from '@/components/DamoFooter.vue'
	export default {
	  name: 'product',
    metaInfo: {
      title: '达摩网络-让信息连接一切，成就所有零售者',
      link: [
        { rel: 'protocol', href: 'https://ding-doc.dingtalk.com/doc#/faquestions/ftpfeu' }
      ]
    },
	  components: {
			DamoHeader,
	    DamoFooter,
      DamoSwiper
	  },
		data() {
			return {
        isfixTab: false,
				scroll: '',
        selectId: null,
        menuList: [{
        	img: require('../static/images/gic.png'),
        	imgs: require('../static/images/gic_pre.png'),
        	texta: 'GIC系统',
        	textb: '企业营销中台'
        }, {
        	img: require('../static/images/hb.png'),
        	imgs: require('../static/images/hb_pre.png'),
        	texta: '好办',
        	textb: '导购管理平台'
        }, {
        	img: require('../static/images/gyt.png'),
        	imgs: require('../static/images/gyt_pre.png'),
        	texta: '观云台',
        	textb: '移动数据BI'
        }, {
        	img: require('../static/images/xcx.png'),
        	imgs: require('../static/images/xcx_pre.png'),
        	texta: '公众号小程序',
        	textb: '顾客触达平台'
        }, {
        	img: require('../static/images/datahook.png'),
        	imgs: require('../static/images/datahook_pre.png'),
        	texta: 'DataHook',
        	textb: '数据管理平台'
        }],
				gicList: [{
					img: require('../static/images/gic_icon01.png'),
					imgs: require('../static/images/gic_icon01_pre.png'),
					texta: '全渠道会员融合',
					textb: '会员生命周期管理',
					textc: '全渠道会员融合 | 会员生命周期管理',
					textd: '打通线上线下会员池，链通多渠道数据来源，完善会员成长体系，构建多维度会员画像，实现会员在多个渠道的身份识别和权益互通，便于品牌会员统一管理、集中分析、精细化运营。',
					texte: '会员列表 会员体系 会员任务 会员成就 会员标签 会员分组 会员分析'
				}, {
					img: require('../static/images/gic_icon02.png'),
					imgs: require('../static/images/gic_icon02_pre.png'),
					texta: '千人千面',
					textb: '精准营销',
					textc: '千人千面 | 精准营销',
					textd: '360°会员画像结合智能营销手段，实现以目的为导向的精准营销。通过全域营销扩大传播力，提升会员复购及客单价。',
					texte: '微信营销 游戏营销 智能引擎 卡券营销 电话营销 短信营销 模板消息'
				}, {
					img: require('../static/images/gic_icon03.png'),
					imgs: require('../static/images/gic_icon03_pre.png'),
					texta: '数据化管理',
					textb: '降本提效',
					textc: '数据化管理 | 降本提效',
					textd: '各项指标数据化、门店任务自动化、会员信息透明化，帮助员工提高工作效率，节省企业经营成本。',
					texte: '企业管理 积分结算 指标管理 日报管理 绩效管理 任务管理 员工管理 公告管理'
				}, {
					img: require('../static/images/gic_icon04.png'),
					imgs: require('../static/images/gic_icon04_pre.png'),
					texta: '数据共享',
					textb: '信息同步',
					textc: '数据共享 | 信息同步',
					textd: '连通品牌ERP数据，一键共享商品资料，有效管理商品信息，实现商品、订单、物流、售后的全渠道同步。',
					texte: '门店商品 FAB管理 规则配置 商品字段管理'
				}, {
					img: require('../static/images/gic_icon05.png'),
					imgs: require('../static/images/gic_icon05_pre.png'),
					texta: '自定义装修',
					textb: '个性化配置',
					textc: '自定义装修 | 个性化配置',
					textd: '可视化组件随意拖曳，自定义装修商城界面，结合会员标签和大数据分析，不同会员看到不同的组件及内容，实现千人千面的内容输出。',
					texte: '配置中心 主题设置 导航设置 自定义页面 广告位管理 公众号配置'
				}],
				navList: [1, 2, 3, 4, 5],
				indexnum: 2,
        menunum: 0,
        num: 1
			}
		},
		watch: {
		  scroll: function () {
		    this.loadSroll()
		  }
		},
		mounted() {
      window.addEventListener('scroll', this.handleTabFix, true)
      this.selectId = localStorage.getItem("toId");
      if(this.selectId) {
        this.jump(this.selectId)
        setTimeout(() => {
          localStorage.setItem("toId",'')
        }, 500);
      }
		},
		methods: {
			changeActive($event, _index){
        var that = this;
        clearTimeout(that.timer);
        if(that.flag){
          if(!that.timer){ //第一次进入
            that.doCount(_index)
          }
          that.timer = setTimeout(()=>{
            that.timer = null
          }, 100)
        }else{					
          that.timer = setTimeout(()=>{
            that.doCount(_index)
          }, 100)
        }        
			},
      doCount(_index) {
        this.indexnum = _index
      },
      doCounts() {
        this.indexnum = 2
      },
			removeActive($event){
        // var that = this;
        // clearTimeout(that.timer);
        // if(that.flag){
        //   if(!that.timer){ //第一次进入
        //     that.doCounts();
        //   }
        //   that.timer = setTimeout(()=>{
        //     that.timer = null;
        //   }, 400)
        // }else{					
        //   that.timer = setTimeout(()=>{
        //     that.doCounts();
        //   }, 400)
        // }        
			},
			handleTabFix() {
			  this.scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			  var offsetTop = document.querySelector('#testNavBar').offsetTop
			  this.scroll > offsetTop ? this.isfixTab = true : this.isfixTab = false
				// console.log(this.scroll+'opo'+offsetTop)
			},
			
			jump(index) {
        var self = this;
			  let jump = document.getElementsByClassName('section')
			  // 获取需要滚动的距离
			  let total = jump[index].offsetTop - 150
        // console.log(total)
        $('html, body').animate({ scrollTop: total }, { duration: 500,easing: "swing" })
        return false
			},
			loadSroll() {
			  var self = this;
			  // var $navs = $(".Navbar-div");
			  // var sections = document.getElementsByClassName('section');
				// console.log(self.scroll)
        if(self.scroll < 900){
          this.menunum = 0
          // $navs.eq(0).addClass("current").siblings().removeClass("current")
        } else if(self.scroll > 901 && self.scroll < 1678){
          this.menunum = 1
        } else if(self.scroll > 1700 && self.scroll < 2206){
          this.menunum = 2
        } else if(self.scroll > 2300 && self.scroll < 2650){
          this.menunum = 3
        } else if(self.scroll > 2700){
          this.menunum = 4
        }
			  // for (var i = sections.length - 1; i >= 0; i--) {
          // console.log(self.scroll+'d'+sections[i].offsetTop+'v'+i)
					// if (self.scroll >= sections[i].offsetTop) {
					// 	$navs.eq(i).addClass("current").siblings().removeClass("current")
					// 	break
					// }
			  // }
			},			
			handleFoot(e) {
				this.jump(e)
			}
		},
    beforeRouteLeave (to, from, next) {
			// this.$refs.DamoSwiper.handleOut()
      window.removeEventListener('scroll', this.handleTabFix, true)
      next()
    }
	}
	</script>
<style>
  .Navbar-box .Navbar-div{
    width: 240px;
    float: left;
    height: 140px;
    background: #FFFFFF;
    overflow: hidden;
    position: relative;
  }
  .Navbar-box .Navbar-div:hover{
    cursor:pointer
  }
  .Navbar-div .menu-img{
    width: 100px;
    height: 138px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform .8s
  }
  .Navbar-box .Navbar-div:hover .menu-img{
    transform: scale(1.1);
  }
  .Navbar-div .menu-div{
    position: absolute;
    top: 0;
    left: 101px;
    width: 116px;
    padding: 54px 0 0 10px;
  }
  .menu-div .p01{
    color: #1C2E4A;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
  }
  .Navbar-box .Navbar-div:hover .menu-div .p01{
    color: #023E99;
  }
  .menu-div .p02{
    color: #60708F;
    font-size: 14px;
    margin-top: 5px;
  }
  .current .menu-div .p01{
    color: #023E99;
  }
	.gic-boxs{
		height: 300px;
		padding: 27px 0;
		box-sizing: border-box;
    margin-bottom: 70px;
	}
	.gic-boxs-div{
		width: 100%;
		height: 250px;
		box-sizing: border-box;
		border: 1px solid #E1E6EE;
	}
	.gic-boxs-div .gic-div{
		width: 175px;
		height: 248px;
		padding-top: 25px;
		float: left;
		box-sizing: border-box;
		border-right: 1px solid #E1E6EE;
		background-image: url(../static/images/gic_bg.png);
		background-position: center bottom;
		background-repeat: no-repeat;
	}
	.gic-boxs-div .moren:last-child .gic-div{
		border-right: none
	}	
	.gic-boxs-div .moren .gic-div{
		width: 175px;
		height: 248px;
		float: left;
		position: relative;
		transition: width .6s;
		-moz-transition: width .6s;
		-webkit-transition: width .6s;
		-o-transition: width .6s;		
	}	
	.gic-boxs-div .moren-show .gic-div{
		width: 498px;
	}
	.gic-boxs-div .gic-on-div{
		width: 100%;
		height: 248px;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;		
		transition: all .4s;
		-moz-transition: all .4s;
		-webkit-transition: all .4s;
		-o-transition: all .4s;
	}
	.gic-boxs-div .moren-show .gic-on-div{
		opacity: 1
	}
	.gic-boxs-div .moren-show .gic-off{
		opacity: 0
	}
	.gic-boxs-div .gic-on {
		width: 100%;
		height: 250px;
		position: absolute;
		left: 0;
		top: -1px;
		padding-top: 17px;
		padding-left: 40px;
		box-sizing: border-box;
		background-image: url(../static/images/gic_bg_pre.jpg);
		background-position: center bottom;
		background-repeat: no-repeat;
    box-shadow: 3px 0 15px rgba(204,204,204, .4);
		z-index: 2;
	}
	.gic-on .p01{
		font-size: 16px;
		color: #FFFFFF;
		line-height: 22px;
		margin-bottom: 12px;
	}
	.gic-on .p02{
		width: 443px;
		color: #FFFFFF;
		font-size: 14px;
		padding-right: 10px;
		padding-bottom: 12px;
		box-sizing: border-box;
		line-height: 20px;
		box-sizing: border-box;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		margin-bottom: 12px;
	}
	.gic-on .p03{
		line-height: 20px;
		font-size: 14px;
		color: rgba(255, 255, 255, 0.59);
		margin-bottom: 4px;
	}
	.gic-on .p04{
		line-height: 20px;
		width: 433px;
		font-size: 14px;
		color: #FFFFFF;
	}
	.gic-boxs-div .gic-off .gic-off-img{
		width: 60px;
		height: 68px;
		display: block;
		margin: 0 auto 20px;
	}
	.gic-off-pre {
		width: 46px;
		height: 46px;
		display: block;
		margin-bottom: 12px;
	}
	.gic-boxs-div .gic-off .p01{
		color: #1C2E4A;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		margin-bottom: 8px;
	}
	.current {
	  color: #fff;
	  background: red;
	}
	
	.product-title{
    font-size: 22px;
    color: #1C2E4A;
    position: relative;
    line-height: 30px;
    height: 30px;
    padding-left: 42px;
    box-sizing: border-box;
  }
  .product-title:before{
    content: "";
    width: 19px;
    height: 5px;
    background: #023E99;
    position: absolute;
    top: 8px;
    left: 0;
    border-radius:5px;
    display: block;
  }
  .product-title:after{
    content: "";
    width: 17px;
    height: 5px;
    background: #C2D7F6;
    position: absolute;
    top: 17px;
    left: 10px;
    border-radius:5px;
    display: block;
  }
  .gic-box{
    margin-top: 90px;    
  }
  .app-box{
    background: #FBFCFF;
    padding-top: 55px;
    padding-bottom: 30px;
		margin-bottom: 70px;
  }
  .gyt-boxl{
    width: 1173px;
    height: 353px;
    background-image: url(../static/images/gyt_bg.png);
    background-repeat: no-repeat;
    margin: 25px auto 120px;
    box-sizing: border-box;
    padding-left: 645px;
    padding-top: 100px;
  }
  .gyt-boxl .p01, .gyt-boxl .p02{
    font-size: 18px;
    color: #1C2E4A;
    line-height: 26px;
    font-weight: bold;
    float: left;
    padding-right: 16px;
    margin-right: 16px;
    position: relative;
  }
  .gyt-boxl .p01:after{
    content: "";
    position: absolute;
    top: 6px;
    right: 0;
    background: #8E9FC0;
    width: 1px;
    height: 13px;
    display: block;
  }
  .gyt-boxl .p03{
    width: 380px;
    font-size: 14px;
    margin-top: 23px;
    line-height: 20px;
    color: #60708F;
  }
  .datahook-box{
    width: 986px;
    height: 160px;
    background-image: url(../static/images/datahook_bg.png);
    background-repeat: no-repeat;
    margin: 40px auto 60px;
    font-size: 16px;
  }
  .xx-bgs{
    background-image: url(../static/images/hb_bg.png);
    background-position: right center;
    background-repeat: no-repeat;
  }
  .datahook-box .div01, .datahook-box .div03{
    line-height: 24px;
    color: #1C2E4A;
    width: 460px;
    height: 100px;
    box-sizing: border-box;
    padding-top: 58px;
    padding-left: 60px;
    float: left;
  }
  .datahook-box .div03{
    padding-top: 45px;
    padding-left: 84px;
  }
  .datahook-box .div02{
    line-height: 19px;
    color: #ffffff;
    width: 20px;
    height: 100px;
    float: left;
    margin-left: 24px;
    padding-top: 24px;
    box-sizing: border-box;
  }
  .xcx-title{
    text-align: center;
    color: #1C2E4A;
    font-size: 16px;
    margin: 4px 0 30px;
    line-height: 20px;
    height: 20px;
  }
  .xcx-line{
    padding: 0 14px;
    margin-right: 13px;
    position: relative;
  }
  .xcx-line:after{
    content: "";
    width: 1px;
    height: 13px;
    background: #8E9FC0;
    display: block;
    position: absolute;
    top: 3px;
    right: 0;
  }
  .cxc-con{
    width: 740px;
    margin: 0 auto 20px;
    overflow: hidden;
  }
	.cxc-con .div01, .cxc-con .div03{
		padding-top: 33px;
	}
	.cxc-con .div02{
		margin: 0 10px;
	}
	.cxc-con-line {
		width:69px;
		height:3px;
		background:linear-gradient(90deg,rgba(65,171,255,1) 0%,rgba(14,107,230,1) 100%);
		margin: 17px auto 10px;
	}
	.cxc-con-tle{
		color: #60708F;
		font-size: 14px;
		text-align: center;
	}
  .fixedNavbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }
  .pNavbar {
    box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.1);
    margin-top: -40px;
    transition:margin-top .3s;
    -moz-transition:margin-top .3s; 
    -webkit-transition:margin-top .3s; 
    -o-transition:margin-top .3s;
    position: relative;
    z-index: 99;
    height: 140px;
  }
  .fixedNavbar .pNavbar{
    margin-top: 0px;
  }
</style>