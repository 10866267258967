<template>
	<div>
		<div class="swiper-box">
			<div class="swiper-container gallery-thumbs">
			  <div class="swiper-wrapper">
			    <div v-for="(item, index) in pressList" :key="index" class="swiper-slide hb-appl-off" style="height: auto;" v-on:mouseover="changeS($event, index)">
						<img class="img-off i-back" :src="item.img">
						<img class="img-off i-front" :src="item.url">
						<div class="div-off">
							<p class="p01">{{ item.title }}</p>
							<p class="p02">{{ item.desc }}</p>
						</div>
					</div>
			  </div>
			</div>
			<div class="swiper-container gallery-top">
			  <div class="swiper-wrapper">
			    <div class="swiper-slide">
						<img src="../static/images/app03.png" />
						<img class="none" src="../static/images/app03.gif" />
						<div class="pressText">- 360会员资料库 人群洞察分组 -</div>
					</div>
			    <div class="swiper-slide">
						<img src="../static/images/app02.png" />
						<img class="none" src="../static/images/app02.gif" />
						<div class="pressText">- 任务追踪 服务评价 数据赋能 移动BI -</div>
					</div>
			    <div class="swiper-slide">
						<img src="../static/images/app01.png" />
						<img class="none" src="../static/images/app01.gif" />
						<div class="pressText">- 商品资料库 商品风向标 商品会员画像 -</div>
					</div>
			    <!-- <div class="swiper-slide">
						<img src="../static/images/app04.png" />
						<div class="pressText">- 信息流高效统一  信息流可视化 -</div>
					</div> -->
			  </div>
			  <!-- Add Pagination -->
			  <div class="swiper-pagination"></div>
			</div>
		</div>
	</div>
	
</template>

<script>
import 'swiper/css/swiper.css'
import Swiper from "swiper"
export default {
  name: 'DamoHeader',
  props: {
    msg: String
  },
  data() {
  	return {
      isfixTab: false,
  		pressList: [{ 
				name: '1', 
				isShow: false ,
				title: '会员数字化',
				desc: '会员可识别、可互动、可触达、可营销、可服务。消费行为分析、标签化管理，便于导购深入洞察会员消费心理，精细化会员服务。',
				url: require('../static/images/hb_icon01.png'),
				img: require('../static/images/hb_icon01_pre.png'),
				info: '- 360会员资料库 人群洞察分组 - '
			}, { 
				name: '2', 
				isShow: false ,
				title: '管理数字化',
				desc: '话务任务、业绩指标自动下发，移动化数据报表，便于员工自主掌控指标进度，及时调整运营策略，提高工作效能。管理工作的数字化推进，也有助于企业实现管理标准化、规范化。',
				url: require('../static/images/hb_icon02.png'),
				img: require('../static/images/hb_icon02_pre.png'),
				info: '- 精准用户触达 高效任务追踪  - '
			}, { 
				name: '3', 
				isShow: false ,
				title: '商品数字化',
				desc: '打造在线商品库，可多条件筛选、排列商品，销售数据直观、销售攻略全面，便于快速掌握商品信息。',
				url: require('../static/images/hb_icon03.png'),
				img: require('../static/images/hb_icon03_pre.png'),
				info: '- FAB解析 商品风向标 - '
			}],
  		numList: ['p0', 'p1', 'p2'],
  		imgIndex: 0,
  		imgTimer: null,
  		btnShow: false
  	}
  },
  mounted() {
		var galleryThumbs = new Swiper('.gallery-thumbs', {
			direction: 'vertical',
		  slidesPerView: "auto",
		  spaceBetween: 0,
		  freeMode: true,
		  watchSlidesVisibility: true,
		  watchSlidesProgress: true,
		});
		var swiper = new Swiper('.gallery-top', {
		  slidesPerView: 3,
		  spaceBetween: -40,
			centeredSlides: true,
			loop: true,
			autoplay: {
				delay: 8000,
				stopOnLastSlide: false,
				disableOnInteraction: true,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
		  thumbs: {
		    swiper: galleryThumbs
		  }
		});
		// var img3DList = document.querySelectorAll(".swiper-pagination-bullet");
		// console.log(img3DList)
		$(".swiper-slide-visible").hover(function() {
			
		},function() {
				swiper.autoplay.start(); //鼠标移出之后，自动轮播开启
		})
  },
	methods: {
		changeS($event, _index){			
			var img3DList = document.querySelectorAll(".swiper-pagination-bullet");
			img3DList[_index].click();
		}
	}
}
</script>

<style>
	.gallery-top {
	  width: 530px;
		height: 590px;
		float: left;
	}
	.gallery-top .swiper-wrapper{
		-webkit-align-items: center;
		align-items: center;
	}
	.gallery-top .swiper-slide {
	  text-align: center;
	  font-size: 18px;
		width: 200px;
		height: 470px;	  
		transition: 300ms;
		transform: scale(1);
		position: relative;
	}
	.gallery-top .swiper-slide .none{
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	.gallery-top .swiper-slide{
		opacity: 0;
	}
	.gallery-top .swiper-slide-active,.swiper-slide-duplicate-active{
		transform: scale(1.1);
		position: relative;
		z-index: 99;
		opacity: 1
	}
	.gallery-top .swiper-slide-next, .gallery-top .swiper-slide-prev{
		position: relative;
		z-index: 90;
		opacity: .4;
	}
	.gallery-thumbs {
		width: 670px;
		height: 644px;
		float: left;
		margin-top: 35px;
	}
	.gallery-thumbs .swiper-slide {
	  width: 100%;
	}
	.gallery-thumbs .swiper-slide-thumb-active {
	  opacity: 1;
	}
	.gallery-top .swiper-slide img{
		width: 100%;
		height: auto;
	}
	
	
.pressText{
	text-align: center;
	color: #1C2E4A;
	font-size: 14px;
	line-height: 18px;
	margin-top: 26px;
	position: absolute;
	bottom: 0;
	width: 128%;
	left: -12%;
	opacity: 0;
}
.hb-appl{
	width: 670px;
	float: left;
}
.hb-appl-off{
	width: 570px;
	padding: 25px 0 0 25px;
	box-sizing: border-box;
	position: relative;
	margin-bottom: 12px;
}
.hb-appl-off .img-off{
	position: absolute;
	top: 0;
	left: 0;
	width: 75px;
	height: 81px;
}
.hb-appl-off .div-off{
	width: 570px;
	padding: 23px 0 19px 90px;
	box-shadow:0px 0px 13px 0px rgba(156,183,207,0.2);
	box-sizing: border-box;
	transition: width 0.4s;
	-moz-transition: width 0.4s; 
	-webkit-transition: width 0.4s;
	-o-transition: width 0.4s;
}
.div-off .i-back{
	display: none;
}
.div-off .i-front{
	display: block;
}
.swiper-slide-thumb-active .i-back{
	display: block;
}
.swiper-slide-thumb-active .i-front{
	display: none;
}
.swiper-slide-thumb-active .div-off, .active .div-off{
	width: 600px;
	box-shadow:0px 0px 13px 0px rgba(156,183,207,0.3);
}
.div-off .p01 {
	color: #1C2E4A;
	height:22px;
	font-size:16px;
	position: relative;
	margin-bottom: 15px;
	font-weight: bold;
}
.swiper-slide-thumb-active .div-off .p01, .active .div-off .p01{
	color: #023E99;
}
.div-off .p01::before{
	content: "“";
	position: absolute;
	top: -13px;
	left: -15px;
	font-size: 31px;
	color: #8E9FC0;
}
.swiper-slide-thumb-active .div-off .p01::before, .active .div-off .p01::before{
	color: #023E99;
}
.div-off .p02{
	line-height:20px;
	font-size:14px;
	color: #8E9FC0;
	width: 460px;
}
.swiper-slide-thumb-active .div-off .p02, .active .div-off .p02{
	color: #023E99;
}
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}
.swiper-box{
	overflow: hidden;
	margin-top: 30px;
}
.boxsq {
  width: 530px;
  height: 540px;
	float: right;
	margin-top: 83px;
}

.main {
  width: 530px;
  height: 440px;
  overflow: hidden;
  position: relative;
}

.item {
  list-style: none;
  width: 200px;
  height: 397px;
  position: absolute;
  left: 0px;
  top: 27px;
  transition: all 0.3s ease;
}

.p0 {
  transform: translate3d(-330px, 0, 0) scale(1);
  opacity: 0;
  z-index: 1;
}

.p1 {
  transform: translate3d(0px, 0, 0) scale(1);
  opacity: 0.2;
  z-index: 2;
}

.p2 {
  transform: translate3d(330px, 0, 0) scale(1);
  opacity: 0.2;
  z-index: 3;
}

.p3 {
  transform: translate3d(155px, 0, 0) scale(1.1);
  opacity: 1;
  z-index: 4;
}

.newItem {
  position: absolute;
  left: 0px;
  top: 0px;
}

.changeItem {
  position: absolute;
  left: 50%;
  top: 0px;
  margin-left: -202px;
  transform: scale(1.4);
}

.item img {
  width: 100%;
  height: 100%;
}

.btnMain {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.left {
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -15px;
  z-index: 10;
}

.right {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -15px;
  z-index: 10;
}

.left img,
.right img {
  width: 30px;
  height: 30px;
}

.pressMain {
  position: absolute;
  left: 50%;
  bottom: 0px;
  width: 134px;
  height: 24px;
  margin-left: -60px;
  z-index: 20;
}

.pressMain span, .swiper-pagination-bullet {
  display: inline-block;
  margin: 2px 3px;
  width: 20px;
  height: 4px;
  border-radius: 4px;
  color: #fff;
  background-color: rgba(108, 164, 248, 0.2);
  text-align: center;
  line-height: 20px;
	opacity: 1
}			
.pressMain .active, .swiper-pagination-bullet-active {
  background:linear-gradient(124deg,rgba(34,187,255,1) 0%,rgba(15,133,255,1) 100%);
}
.swiper-slide-active .pressText{
	opacity: 1;
}
.gallery-top .swiper-slide-active .none{
	opacity: 1;
}
</style>
